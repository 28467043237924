<template>
  <v-app style="background-color: white">
    <v-app-bar
      v-if="['login'].includes($route.name)"
      app
      color="rgba(190, 149, 91, 0.010)"
      dark
      height="100"
      style="border-bottom: 2px solid var(--v-primary-base)"
    >
      <div class="align-start mr-16">
        <v-img src="./assets/logo.png" width="130" height="130" contain></v-img>
      </div>
      <div class="d-flex align-center">
        <span class="primary--text-GM title-text-GM">PORTAL GM CAPITAL</span>
      </div>
    </v-app-bar>
    <AppNavigation v-if="!['login'].includes($route.name)"></AppNavigation>
    <v-main>
      <router-view />
      <router-link to="/dashboard"></router-link>
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */
import UserService from '@/services/UserService';
import { mapActions } from 'vuex';
import { TokenUtils } from '@/utils/TokenUtils';
import * as AppUtils from '@/utils/AppUtils';
import AppNavigation from './components/AppNavigation.vue';

export default {
  name: 'App',
  components: {
    AppNavigation,
  },
  data: () => ({}),
  methods: {
    ...mapActions([
      'setAuthUserData',
      'setNameUser',
      'setAttributes',
    ]),
  },
  async mounted() {
    if (TokenUtils.hasToken()) {
      const payload = TokenUtils.getJwtData();
      const { identity: userId } = payload;
      const attributes = await UserService.seePermissions(userId);
      const name = await UserService.getUser(userId);
      this.setNameUser(name.fullName);
      this.setAuthUserData(payload);
      this.setAttributes(attributes.data);
      if (window.location.pathname === '/') {
        AppUtils.redirectToUserSectionByAttributes(this.$router);
      }
    } else {
      this.$router.push('/login');
    }
  },
  watch: {
    // Observa los cambios en la ruta
    '$route.path'(newRoute) {
      console.log('Ruta actual: ', newRoute);
    }
  }
};
</script>

<style>
.primary--text-GM {
  font-family: "Ubuntu", sans-serif;
  color: var(--v-primary-base);
  font-size: 40px;
}
.title-text-GM {
  font-size: 40px;
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
}
.user-text {
  font-size: 15px;
  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
}
</style>
